//TODO: async loading
import React from 'react';
import L from 'react-loadable';

const modules = [
  {
    path: '/',
    component: L({
      loader: () => import ('./app/routes/dashboard/components/Dashboard'),
      loading: () => null
    })
  },
  {
    path: '/myapps/3ds-server',
    component: L({
      loader: () => import ('./app/routes/myapps/3ds-server/Main'),
      loading: () => null
    }),
  },
  {
    path: '/saas/uptime',
    component: L({
      loader: () => import ('./app/routes/myapps/3ds-server/saas/SaasUpTimeMain'),
      loading: () => null
    }),
  },
  {
    path: '/aa/saas',
    component: L({
      loader: () => import ('./app/routes/myapps/3ds-server/saas_aa/SaasUpTimeMain'),
      loading: () => null
    }),
  },
  {
    path: '/myapps/3ds-server/reg',
    component: L({
      loader: () => import ('./app/routes/myapps/3ds-server/RegApp'),
      loading: () => null
    }),
  },
  {
    path: '/myapps/3ds-server/activate/:id',
    component: L({
      loader: () => import ('./app/routes/myapps/3ds-server/ActivateServer'),
      loading: () => null
    }),
  },
  {
    path: '/myapps/3ds-server/activate/init/:id',
    component: L({
      loader: () => import ('./app/routes/myapps/3ds-server/ServerActivationStepper'),
      loading: () => null
    }),
  },
  {
    path: '/myapps/3ds-server/usage/:id',
    component: L({
      loader: () => import ('./app/routes/myapps/3ds-server/usage/ServerUsageStats'),
      loading: () => null
    }),
  },
  {
    path: '/myapps/sdk',
    component: L({
      loader: () => import ('./app/routes/myapps/sdk/Main'),
      loading: () => null
    })
  },
  {
    path: '/myapps/sdk/reg',
    component: L({
      loader: () => import ('./app/routes/myapps/sdk/RegSDK'),
      loading: () => null
    })
  },
  {
    path: '/myapps/sdk/activate/:id',
    component: L({
      loader: () => import ('./app/routes/myapps/sdk/ActivateApp'),
      loading: () => null
    })
  },
  {
    path: '/myapps/sdk/pricing/:id',
    component: L({
      loader: () => import ('./app/routes/myapps/sdk/SDKPricing'),
      loading: () => null
    })
  },
  {
    path: '/myapps/sdk/token/:platform/:id',
    component: L({
      loader: () => import ('./app/routes/myapps/sdk/ImplementationStepper'),
      loading: () => null
    })
  },
  {
    path: '/docs/:product',
    component: L({
      loader: () => import ('./app/routes/docs/Main'),
      loading: () => null
    })
  },
  {
    path: '/releases/:product/latest',
    component: L({
      loader: () => import ('./app/routes/releases/MainPage'),
      loading: () => null
    })
  },
  {
    path: '/releases/:product/v/:version',
    component: L({
      loader: () => import ('./app/routes/releases/VersionPage'),
      loading: () => null
    })
  },
  {
    path: '/profile',
    component: L({
      loader: () => import ('./app/routes/profile'),
      loading: () => null
    })
  },
  {
    path: '/profile/org',
    component: L({
      loader: () => import ('./app/routes/profile/OrgOnly'),
      loading: () => null
    })
  },
];


export default modules;
